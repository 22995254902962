<!-- 订单信息 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="handGoBack" />
      <div class="header-content">我的订单</div>
    </header>
    <section class="page-info">
      <div v-if="tableList.length > 0">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model:loading="loading"
            :finished="tableList.length==total"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-cell v-for="(item, index) in tableList" :key="index" @click="handleGoDetail(item.order_sn)">
              <div class="bill-creater-info">
                <div class="meal-tag">
                  <div v-if="item.shop_type==1">
                    <div v-if="item.meal_mode==1">
                      早餐
                    </div>
                    <div v-else-if="item.meal_mode==2">
                      午餐
                    </div>
                    <div v-else-if="item.meal_mode==3">
                      晚餐
                    </div>
                    <div v-else>
                      其他
                    </div>
                  </div>
                  <div v-else-if="item.shop_type==3">
                    膳食套餐
                  </div>
                  <div v-else>
                    预制菜
                  </div>
                </div>
                <div class="click-tag">
                  点击查看详情
                </div>
                <div class="bill-sn" style="color:#1989fa;width:100%">
                  就餐日期：{{ getTimeYMD(item.order_dt) }}
                </div>
                <div style="color:#1989fa;">
                  订餐时间：{{ getTimeYMDHMS(item.insert_dt) }}
                </div>
                <div style="color:#FF3333">
                  ￥{{ item.amount }}
                </div>

                <div v-if="item.state==1 && item.pay_state==1 && item.refund_state!=2" style="color:#1989fa;width:100%">
                  订单状态：{{ item.delivery_state_str }}
                </div>
                <div v-else-if="item.state!=1" style="width:100%">
                  订单状态：已取消
                </div>
                <div v-else-if="item.refund_state==2" style="color:#FF3333;width:100%">
                  订单状态：已退款（原路退回）
                </div>
                <div v-else-if="item.state==1 && item.pay_state==0 && item.delivery_state==0" style="color:#FF3333;width:100%">
                  订单状态：{{ item.pay_state_str }}
                </div>
                <div v-else  style="color:#FF3333;width:100%">
                  订单状态：{{ item.pay_state_str }}
                </div>

                <div class="dishes-info" v-if="item.dishes_list!=null && item.dishes_list.length > 0">
                  <van-divider :style="{ padding: '5px 0',margin:'0 0' }" />
                  <div class="meal-title" >
                    餐食列表：
                  </div>
                  <div  v-for="(d_item, d_index) in item.dishes_list" :key="d_index">
                    <van-row gutter="10">
                      <van-col span="12">
                        <div class="dish-name">
                          {{d_item.dishes_name}}
                        </div>
                      </van-col>
                      <van-col span="6">
                        <div class="dish-num">
                          x{{d_item.dishes_num}}
                        </div>
                      </van-col>
                      <van-col span="6">
                        <div class="dish-price">
                          ￥{{d_item.pay_price}}
                        </div>
                      </van-col>
                    </van-row>
                  </div>
                </div>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
      <div v-else class="shopping-card-none">
        <div v-if="fetchLoading" style="margin-top:10vh;">
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
        <div v-else>
          <van-empty image-size="10vh" image="error" description="暂无数据" />
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant'

import { getTimeYMD, getTimeYMDHMS } from "@/utils/util"
import CommonData from "../../utils/commonData"
import { getStorage, setStorage } from "../../utils/localStorageUtils"

import order from "@/api/ofs/order"

export default {
  name: "bill",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      getOrderList()
    });

    const data = reactive({
      page_index: 1,
      page_size: 10,
      loading: false,
      total: 0,
      tableList: [],
      fetchLoading: false,
      refreshing:false
    });

    const onLoad = () => {
      console.log("加载更多！")
      data.page_index += 1
      getOrderList()
    }
    const onRefresh = () => {
      console.log("下拉刷新！")
      data.page_index = 1
      data.loading = true
      data.tableList = []
      getOrderList()
    }

    const getOrderList = () => {
      const post_data = {
        page_index: data.page_index,
        page_size: data.page_size,
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID)
      }
      data.fetchLoading = true
      data.refreshing = true
      order.getOrderList(post_data).then(res=> {
        data.total = res.data.total
        if (data.page_index == 1) {
          data.tableList = res.data.data
        } else {
          data.tableList = [...data.tableList, ...res.data.data]
        }
        data.loading = false
        data.fetchLoading = false
      }).catch(err=>{
        data.fetchLoading = false
      })
    }
    const handleGoDetail = sn => {
      const params = {
        order_sn: sn
      }
      $router.push({
        query: params,
        name: 'bill-info'
      })
    }

    const handGoBack = () => {
      $router.push({
        name: "index"
      })
    }

    return {
      ...toRefs(data),
      handleGoDetail,
      getTimeYMD,
      getTimeYMDHMS,
      handGoBack,
      onLoad,
      onRefresh
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    background-color: #fff;
    min-height: 300px;
    // margin: 20px;
    :deep(.van-cell:after) {
      border: none;
    }
    .bill-creater-info {
      // margin: 20px 0;
      border-radius: 20px;
      background: #fafafa;
      padding: 20px;
      font-size: 14px;
      line-height: 24px;
      color: #AAAAAA;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      .bill-sn {
        margin-top: 15px;
      }
      .dishes-info {
        width: 100%;
        color: #1989fa;
        .meal-title {
          color: #1989fa;
          font-weight: 800;
        }
        .dish-name {
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow:ellipsis;
        }
        .dish-num {
          text-align: center;
        }
        .dish-price {
          text-align: center;
        }
      }
      .meal-tag {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        padding: 3px 10px;
        color: #fff;
        background: #99BBFF;
        border-radius: 12px 0 12px 0;
      }
      .click-tag {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        padding: 3px 10px;
        color: #fff;
        background: #9F88FF;
        border-radius: 0 12px 0 12px;
      }
    }
    .bill-detail {
      .meal-type {
        font-size: 20px;
        color: #666666;
        margin: 10px 0;
      }
      .bish-detail {
        margin: 10px 0;
        font-size: 16px;
        line-height: 20px;
        color: #AAAAAA;
        .dish-name {
          color: #000000;
        }
        .dish-num {
          text-align: center;
        }
        .dish-price {
          text-align: center;
        }
      }
      .bill-evaluate {
        margin: 20px 0;
        width: 100%;
        text-align: right;
      }
    }
    .shopping-card-none {
      height: 100%;
      font-size: 18px;
      color: #AAAAAA;
    }
  }
}
</style>
